<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    authority="Users_Payment"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './storeModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      bankOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'bank-accounts',
        endpoint: 'bank-accounts',
        route: 'bank-accounts',
        title: {
          single: this.$i18n.t('Bank Account'),
          plural: this.$i18n.t('Bank Accounts'),
        },
      }
    },
    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'bank.title', label: this.$t('bank') },
        { key: 'iban', label: this.$t('iban'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formFields() {
      return [
        {
          id: 'bank',
          type: 'select',
          object: true,
          label: this.$i18n.t('bank'),
          required: true,
          options: this.bankOptions,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: true,
        },
        {
          id: 'iban',
          type: 'text',
          label: this.$t('iban'),
          required: true,
        },
        {
          id: 'description',
          type: 'textarea',
          label: this.$t('description'),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchBanks')
      .then(response => {
        this.bankOptions = response.data
      })
  },
}
</script>
